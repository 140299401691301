<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content content-resto" v-if="active"  id="scrollcontainer" ref="container" v-on:scroll="measure()">
   <transition name="content-loading-fade">
        <div class="content-loading" v-if="loading"></div>
    </transition>
    <div class="content-fix-menu" v-if="data">

        <router-link 
            tag="a"
            v-bind:key="key" 
            :to="{ name: $route.name, params: { lang: $root.language[$root.languageid], type: $route.name, name: $root.u(row.Contribution.Name),  id: row.Contribution.Id }}"
            v-for="(row, key) in data.Documents"
        >
                <span 
                  class="content-fix-menu-link content-fix-menu-link-large" 
                  ref="sublink"
                  v-scroll-to="{el: `#element${row.Contribution.Id}`, container: '#scrollcontainer', offset: -90}" 
                  v-html="row.Data.Bezeichnung.Content[$root.languageid]"
                ></span>
        </router-link>  


        <router-link 
            tag="a"
            :to="{ name: $route.name, params: { lang: $root.language[$root.languageid], type: $route.name, name: 'Worklist' }}"
            v-if="(orderedUsers && orderedUsers.length > 0)"
        >
                <span 
                  class="content-fix-menu-link content-fix-menu-link-large" 
                  ref="sublink2"
                  v-scroll-to="{el: `#element_worklist`, container: '#scrollcontainer', offset: -90}" 
                  v-html="['Werkliste','List of Works', '..'][$root.languageid]"
                ></span>

        </router-link>

    </div>
    <div class="content-fix-content" v-if="data">
      <div 
        class="content-fix-content-document content-padding-bottom-double  content-margin-bottom-double"
        v-bind:key="key" 
        ref="anchor"
        v-for="(post, key) in data.Documents"
      >
          <h1 :ref="`element${post.Contribution.Id}`" :id="`element${post.Contribution.Id}`" class="lauftext lauftext-padding-bottom content-fix-content-section" v-html="post.Data.Bezeichnung.Parsed[$root.languageid]"></h1>
          <div>
            <div class="swiper" v-if="post.Data.Bild.Content">
                <swiper ref="mySwiper"  :options="$root.swiperOption">
                  <swiper-slide :data-caption="img.Captions[$root.languageid]"  :data-hash="`img-${$root.u(post.Contribution.Name)}-${index}`" v-bind:key="index"  v-for="(img, index) in post.Data.Bild.Content">
                      <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
                          <img class="img-container" :data-src="img.Files.Resized[2]"/>
                      </div>
                  </swiper-slide>
                  <div v-if="post.Data.Bild.Content.length > 1" class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                  <div v-if="post.Data.Bild.Content.length > 1" class="swiper-button-next swiper-button-black" slot="button-next"></div>
                  <div v-if="post.Data.Bild.Content.length > 1" class="swiper-pagination swiper-pagination-small"  slot="pagination"></div>
                </swiper>
            </div>
            <div class="block">
                <div v-if="post.Data.Text.Parsed[$root.languageid]" class="lauftext" v-html="post.Data.Text.Parsed[$root.languageid]"></div>
                <div v-if="post.Data.Tabelle && post.Data.Tabelle.Content && post.Data.Tabelle.Content.length > 0" class="lauftext">
                  <table>
                    <tbody>
                      <tr v-bind:key="tblk" v-for="(tbl, tblk) in post.Data.Tabelle.Content">
                        <td v-html="tbl[$root.languageid]"/>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
            <div class="block block-padding-top"  v-if="post.Data.Techniken.Reference && (orderedUsers && orderedUsers.length > 0)">  
                <router-link 
                    class="tag tag-medium tag-margin-right" 
                    v-bind:key="key" 
                    :to="{ name: 'Technik', params: { lang: $root.language[$root.languageid], type: 'Technik', id: r }}"
                    v-for="(r, key) in post.Data.Techniken.Content" 
                    v-if="post.Data.Techniken.Reference[r].Bezeichnung"
                    v-html="post.Data.Techniken.Reference[r].Bezeichnung.Parsed[$root.languageid]"
                >
                </router-link>
            </div>            
          </div>


      </div>
      
      <div class="content-fix-content-document content-padding-bottom-double content-margin-bottom-double fixtable"
        ref="anchor2"
        v-if="orderedUsers && orderedUsers.length > 0"
      >
        <h1 :ref="`element_worklist`" :id="`element_worklist`" class="lauftext lauftext-padding-bottom content-fix-content-section" v-html="['Werkliste','List of Works', '..'][$root.languageid]"></h1>
        <div>
          <table>
            <thead>
                <tr>
                    <th>    
                        <a :class="ordercol=='Data._sortartist' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data._sortartist'}">Name</a>
                    </th>
                    <th>
                        <a :class="ordercol=='Data.Werktitel.Content[0]' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Werktitel.Content[0]'}" v-html="['Titel', 'Title'][$root.languageid]"></a>
                    </th>
                    <th>
                        <a :class="ordercol=='Data.Jahr.Content' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Jahr.Content'}" v-html="['Jahr', 'Year'][$root.languageid]"></a>
                    </th>
                </tr>
            </thead>
            <tbody>
              <router-link 
                  tag="tr"
                  v-bind:key="key" 
                  :to="{ name: 'Work', params: { lang: $root.language[$root.languageid], type: 'Work', name: $root.u(row.Contribution.Name),  id: row.Contribution.Id }}"
                  v-for="(row, key) in orderedUsers"
                  v-if="row.Contribution.Status == 'Close' || loadstate == 'both'"
              >
                      <td v-html="row.Data._artist"></td>
                      <td v-html="row.Data.Werktitel.Content[$root.languageid]"></td>
                      <td v-if="row.Data.JahrDarstellung.Content">{{row.Data.JahrDarstellung.Content}}</td>
                      <td v-else>{{row.Data.Jahr.Content | yearformat}}</td>
              </router-link>  
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)
import _ from 'lodash'

export default {
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    orderedUsers: {
      get: function () {
          return this.worklist ? _.orderBy(this.worklist, this.ordercol, this.ordermode) : null
      },
      set: function (value) {
          if (this.ordercol == value.order) {
              this.ordermode = this.ordermode == 'desc' ? 'asc' : 'desc'
          }
          else {
              this.ordercol = value.order
              this.ordermode = 'asc'
          }
      }
    }
  },
  data () {
    return {
      ordercol: 'Data.Jahr.Content',
      ordermode: 'desc',
      loading: false,
      data: null,
      row: null,
      error: null,
      active: false,
      techtag: 1116,
      worklist: null,
      loadstate: window.instance.defaults.params['status'],
      swiperOption: {
        autoHeight: false, //enable auto height
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    if (this.active != this.$route.params.id) {
        this.error = this.data = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.active != this.$route.params.id) {
            this.data = null;
            this.fetchData()
        }
    }
  },
  methods: {
    measure() {
      if (this.$refs.anchor)
        this.$refs.anchor.forEach((c,i) => {
          if (this.$refs.sublink[i]) {
            if (c.offsetTop - this.$refs.container.scrollTop < this.$refs.sublink[i].getBoundingClientRect().top) {
              this.$refs.sublink[i].classList.add("content-fix-menu-link-hidden");
            }
            else {
              this.$refs.sublink[i].classList.remove("content-fix-menu-link-hidden");
            }
          }
        })
      if (this.$refs.anchor2 && this.$refs.sublink2) {
        if (this.$refs.anchor2.offsetTop - this.$refs.container.scrollTop < this.$refs.sublink2.getBoundingClientRect().top) {
          this.$refs.sublink2.classList.add("content-fix-menu-link-hidden");
        }
        else {
          this.$refs.sublink2.classList.remove("content-fix-menu-link-hidden");
        }
      }
    },

    fetchData () {
      this.loading = true
      this.active = this.$route.params.id || "emtpy"
      this.loadstate = window.instance.defaults.params['status']

      try {
        this.$store.dispatch('loadData', {
          mode: 'contributions', 
          issue: this.$route.meta.issue.Id, 
          chapter: this.$route.meta.chapter.Id 
        }).then((data) => {
          this.data = data
          

          if (!this.$route.params.id) {
            this.$root.addBreadcrumb(this.$route.meta.translation);
            this.$root.applyMeta(`${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)            
          } else {
            this.data.Documents.forEach((_d) => {
              if (_d.Contribution.Id == this.$route.params.id) {
                this.$root.addBreadcrumb(_d.Data.Bezeichnung.Parsed);
                this.$root.applyMeta(`${_d.Data.Bezeichnung.Content[this.$root.languageid]} - ${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)            
              }
            })
          }
          /*
          try {
            this.$root.addBreadcrumb(this.$root.menu[this.post.Contribution.ForissueName].translation);  
          } catch (error) {
            
          }*/
          this.$store.dispatch('loadData', {
            mode: 'contribution', 
            chapter: this.techtag
          }).then((data) => {
            let _worklist = data.Contribution.ReferencedFrom
            this.worklist = [];
            _worklist.forEach(element => {
              if (element.Contribution.Fortemplate == 2) {
                try {
                  element.Data.Jahr.Content = 1*element.Data.Jahr.Content;
                } catch(error) {
                  element.Data.Jahr = {};
                  element.Data.Jahr.Content = false;
                  console.warn(element);
                }
                try {
                  element.Data._artist = "";
                  element.Data._artist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 3 : 1]
                  if (element.Data._artist != "")
                    element.Data._artist += " "
                  element.Data._artist += element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 2 : 0]
                  element.Data._sortartist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 2 : 0];
                  
                } catch (error) {
                  element.Data._artist = '<font color="#F00">Reference Missing!</font>'
                }
                this.worklist.push(element);
              }
            });


            this.loading = false;
            console.log(this.worklist);
          });



          this.$nextTick(() => {
            this.measure()
            if (this.$route.params.id) {
              this.$scrollTo(this.$refs[`element${this.$route.params.id}`][0], 1, {container: '#scrollcontainer', offset: -90})
            }
          })
        });  
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>


<style lang="scss">

  @import "../main";
  @import "../slider";
  
  .content-resto {
    .fixtable {
      margin-top: $margin-standard * -1.3;
      h1 {
        padding-top: $margin-standard * 1.3 / $fac_largefont;
      }
    }
    table {
      display: table;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      & tr {
        height: ($header-size - ($margin-standard / 2)) / 2;
        border-bottom: $standard-border;
        cursor: pointer;
      }

      & > tbody > tr,
      & > thead > tr {
        & > td:nth-child(1), 
        & > th:nth-child(1) { 
          width: 40%; 
        }
        & > td:nth-child(2), 
        & > th:nth-child(2) { 
          width: 40%; 
          padding: $margin-standard / 2;
          font-style: italic;
        }
        & > td:nth-child(3), 
        & > th:nth-child(3) { 
          width: 20%; 
          text-align: right;
          @include sm() {
            display: none;
          }
        }
      }


      td, th {
        font-weight: normal;
        text-align: left;
        vertical-align: top;
        padding: $margin-standard / 2 0;
        a {
          margin-left: 1em;
          &:before {
            width: $icon-size / 2;
            height: $icon-size / 2;
            border-top: 2px solid black;
            content: " ";
            position: absolute;
            margin-left: -1em;
            margin-top: 0.6em;
        }      
          &.asc,
          &.desc {
            &:before {
              border-left: 2px solid black;
            }
          }
          
          &.desc {
            &:before {
              transform: rotate(-135deg);
              margin-top: 0.2em;
            }
          }
          &.asc {
            &:before {
              transform: rotate(45deg);
              margin-top: 0.5em;
            }
          }
        }
      }
    }
  }



</style>